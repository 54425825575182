import React from "react";
import {Navigate} from "react-router-dom";
import LocalStorageManager from "../helpers/localStorageManager";
import {toast} from "react-toastify";
import NotFound from "../pages/NotFound";

const Authmiddleware = (props) => {
    const actions = LocalStorageManager.get('user')?.can
    const token = localStorage.getItem("token")
    if (!token) {
        return (
            <Navigate to={{pathname: "/login", state: {from: props.location}}}/>
        );
    }
    if (!actions) {
        toast.error('Sizin heç bir səlahiyyətiniz yoxdur')
        return <Navigate to={{pathname: "/login",}}/>

    }

    const can = props.route.can
    if (Array.isArray(can) && props.route.path !== 'dashboard' && can.some(item => !actions[item])) {
        return <NotFound/>
    } else if (!Array.isArray(can) && !actions?.[can] && props.route.path !== 'dashboard') {
        return <NotFound/>
    }


    return (<React.Fragment>
        {props.children}
    </React.Fragment>);
};

export default Authmiddleware;
