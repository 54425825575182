import ConfirmModal from "components/Common/ConfirmModal";
import Breadcrumbs from "components/Common/Breadcrumb";
import {useEffect, useState} from "react";
import Api from 'api/cashbox2'

import {Badge, Button, Card, CardBody, Col, Label, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import Form from "../../helpers/form";
import {Controller, useForm} from "react-hook-form";
import Employees from "../../api/employees";
import FlatPicker from "react-flatpickr";

const Branches = () => {
    document.title = 'Kassa'
    const [confirmModal, setConfirmModal] = useState(false)
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const {control, getValues, handleSubmit, errors, reset} = useForm({
        defaultValues: {
            start_date: new Date(),
            end_date: new Date()
        }
    })
    const [workers, setWorkers] = useState([])

    const deleteData = async () => {
        await Api.delete(confirmModal)
        fetchData()
    }

    const fetchData = async (showLoader = true) => {
        setIsFetching(showLoader)
        const {data} = await Api.report({
            start_date: Form.convertFormDate(getValues()?.start_date),
            end_date: Form.convertFormDate(getValues()?.end_date)
        })
        setData(data)
        setIsFetching(false)
    }

    const filter = () => {
        fetchData(true)
    }

    const fetchWorkers = async () => {
        const {data} = await Employees.getSelect()
        setWorkers(data)
    }

    useEffect(() => {
        fetchWorkers()
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData}/>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`KASSA`}/>
                <Row>
                    <Col sm={12}>
                        <form onSubmit={handleSubmit(filter)}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <div className="mb-3">
                                                <Label for="start_date">Başlama tarixi</Label>
                                                <Controller name="start_date"
                                                            control={control}
                                                            render={({field: {value, onChange}}) => (
                                                                <FlatPicker
                                                                    className="form-control d-block"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    options={{
                                                                        locale: 'az'
                                                                    }}
                                                                />
                                                            )}/>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="mb-3">
                                                <Label for="end_date">Bitmə tarixi</Label>
                                                <Controller name="end_date"
                                                            control={control}
                                                            render={({field: {value, onChange}}) => (
                                                                <FlatPicker
                                                                    className="form-control d-block"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    options={{
                                                                        locale: 'az'
                                                                    }}
                                                                />
                                                            )}/>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="d-flex gap-2 justify-content-end">
                                                <Button id="reset-btn" color="primary" outline onClick={() => {
                                                    reset({
                                                        start_date: new Date(),
                                                        end_date: new Date(),
                                                    })
                                                    fetchData(true)
                                                }}>
                                                    <i className="bx bx-rotate-right"/>
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target="reset-btn">
                                                    Sıfırla
                                                </UncontrolledTooltip>
                                                <Button color="primary" type="submit">Axtar</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </form>
                    </Col>
                    <Col sm={12}>
                        {isFetching ? (
                            <div className="d-flex justify-content-center p-5">
                                <Spinner color="primary" size="lg"/>
                            </div>
                        ) : (
                            <Row>
                                <Col sm={12} md={4}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="statistics-icon">
                                                    <b>Ümumi</b>
                                                </div>
                                                <Badge className="p-2 font-size-12"
                                                       color="success">{data?.total}</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="statistics-icon">
                                                    <b>Müayinə kassasından gələn</b>
                                                </div>
                                                <Badge className="p-2 font-size-12"
                                                       color="info">{data?.examination_benefit}</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="statistics-icon">
                                                    <b>Müayinə kassasından gələn (Nağd)</b>
                                                </div>
                                                <Badge className="p-2 font-size-12"
                                                       color="info">{data?.examination_benefit_cash}</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="statistics-icon">
                                                    <b>Müayinə kassasından gələn (Kart)</b>
                                                </div>
                                                <Badge className="p-2 font-size-12"
                                                       color="info">{data?.examination_benefit_card}</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="statistics-icon">
                                                    <b>Kassa giriş</b>
                                                </div>
                                                <Badge className="p-2 font-size-12"
                                                       color="primary">{data?.cashbox_in}</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="statistics-icon">
                                                    <b>Kassadan çıxan</b>
                                                </div>
                                                <Badge className="p-2 font-size-12"
                                                       color="danger">{data?.cashbox_out}</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="statistics-icon">
                                                    <b>Kassadan giriş çıxış cəmi</b>
                                                </div>
                                                <Badge className="p-2 font-size-12"
                                                       color="warning">{data?.cashbox_in_out}</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Branches
