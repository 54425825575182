import LocalStorageManager from "./localStorageManager";

class User {
    getFirstChars = (user) => {
        return `${user?.name?.[0]?.toUpperCase()}${user?.surname?.[0]?.toUpperCase() || ''}`
    }

    getId = () => {
        const user = LocalStorageManager.get('user')
        return user?.id
    }
}

export default new User()
