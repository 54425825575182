import {Controller, useFieldArray, useForm} from "react-hook-form";
import {Button, Col, Input, Label, ModalBody, ModalFooter, Row, Spinner} from "reactstrap";
import FormHelper from "../../helpers/form";
import {useEffect, useState} from "react";
import Api from 'api/blank'
import Employees from "../../api/employees";
import Patients from "../../api/patients";
import Select from "react-select";
import User from "../../helpers/user";
import Branches from "../../api/branches";
import Missions from "../../api/missions";
import moment from "moment";
import PDFTemplate from "./PDFTemplate";

const Add = ({fetchData, form, setForm}) => {
    const {control, handleSubmit, setValue, setError, reset, watch, getValues, formState: {errors}} = useForm()
    const [loader, setLoader] = useState(false)
    const [workers, setWorkers] = useState([])
    const [doctors, setDoctors] = useState([])
    const [patients, setPatients] = useState([])
    const [departments, setDepartments] = useState([])
    const [services, setServices] = useState([])
    const [pdf, setPdf] = useState(null)

    const detailsArr = useFieldArray({
        control,
        name: 'details'
    })
    const data = form?.data

    const submit = async values => {
        setLoader(true)
        try {
            if (values?.id) {
                const formData = new FormData()
                if (pdf) {
                    formData.append('pdf', pdf)
                }
                formData.append('id', values?.id)
                formData.append('doctor_id', values?.doctor_id?.value)
                formData.append('patient_id', values?.patient_id?.value)
                formData.append('worker_id', values?.worker_id?.value)
                values?.details?.forEach((item, index) => {
                    Object.keys(item).forEach(x => {
                        if (x === 'department_id') {
                            formData.append(`details[${index}][${x}]`, values?.details?.[index][x]?.value)
                        } else if (x === 'service_id') {
                            formData.append(`details[${index}][${x}]`, values?.details?.[index][x]?.value)
                        } else {
                            formData.append(`details[${index}][${x}]`, values?.details?.[index][x])
                        }
                    })
                })
                await Api.update(formData, values?.id)
            } else {
                await Api.add({
                    ...FormHelper.validateBody(values),
                    details: values?.details?.map(item => {
                        return {
                            ...item,
                            department_id: item?.department_id?.value,
                            service_id: item?.service_id?.value,
                        }
                    })
                })
            }

            fetchData()
            setForm({})
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const fetchDepartments = async () => {
        const {data} = await Branches.getSelect()
        setDepartments(data)
    }

    const fetchServices = async () => {
        const {data} = await Missions.getSelect()
        setServices(data)
    }

    const fetchWorkers = async () => {
        const {data} = await Employees.getSelect()
        setWorkers(data)
    }

    const fetchDoctors = async () => {
        const {data} = await Employees.getDoctors()
        setValue('doctor_id', data?.find(item => item?.value === User.getId()))
        setDoctors(data)
    }

    const fetchPatients = async () => {
        const {data} = await Patients.getSelect()
        setPatients(data)
    }


    useEffect(() => {
        fetchWorkers()
        fetchDoctors()
        fetchPatients()
        fetchDepartments()
        fetchServices()
    }, [])

    useEffect(() => {
        if (data && workers?.length && doctors?.length && patients?.length) {
            reset({
                ...data,
                doctor_id: {
                    value: data?.doctor_id,
                    label: data?.doctor
                },
                patient_id: {
                    value: data?.patient_id,
                    label: data?.patient
                },
                worker_id: {
                    value: data?.worker_id,
                    label: data?.worker
                },
                details: data?.details?.map(item => {
                    return {
                        ...item,
                        department_id: {
                            value: item?.department_id,
                            label: item?.department_name,
                        },
                        service_id: {
                            value: item?.service_id,
                            label: item?.service_name,
                        }
                    }
                })
            })
        }
    }, [form, workers, doctors, patients])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                <Row>
                    <Col sm={12} md={6}>
                        <div className="mb-3">
                            <Label for="doctor_id">Həkim</Label>
                            <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                <Select
                                    options={doctors}
                                    placeholder=""
                                    className={`w-100 ${errors?.doctor_id && 'is-invalid'}`}
                                    onChange={onChange}
                                    value={value}
                                    name="doctor_id"
                                    id="doctor_id"/>
                            )} name="doctor_id" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'doctor_id')}
                        </div>
                        <div className="mb-3">
                            <Label for="patient_id">Pasient</Label>
                            <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                <Select
                                    options={patients}
                                    placeholder=""
                                    className={`w-100 ${errors?.patient_id && 'is-invalid'}`}
                                    onChange={onChange}
                                    value={value}
                                    name="patient_id"
                                    id="patient_id"/>
                            )} name="patient_id" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'patient_id')}
                        </div>
                        <div className="mb-3">
                            <Label for="worker_id">Göndərən həkim</Label>
                            <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                <Select
                                    options={workers}
                                    placeholder=""
                                    className={`w-100 ${errors?.worker_id && 'is-invalid'}`}
                                    onChange={onChange}
                                    value={value}
                                    name="worker_id"
                                    id="worker_id"/>
                            )} name="worker_id" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'worker_id')}
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        {!data?.id && (
                            <>
                                <div className="mb-3 w-100">
                                    <Label for="department_id">Şöbə</Label>
                                    <div className="d-flex w-100 gap-2">
                                        <div className="w-100">
                                            <Controller render={({field: {value, onChange}}) => (
                                                <div className="w-100">
                                                    <Select
                                                        options={departments}
                                                        placeholder=""
                                                        className={`w-100 ${errors?.department_id && 'is-invalid'}`}
                                                        onChange={onChange}
                                                        value={value}
                                                        name="department_id"
                                                        id="department_id"/>
                                                </div>
                                            )} name="department_id" control={control}/>
                                            {FormHelper.generateFormFeedback(errors, 'department_id')}
                                        </div>
                                        {!data?.id && (
                                            <Button color="primary"
                                                    disabled={!watch('department_id') || !watch('service_id') || !watch('norm')}
                                                    onClick={() => {
                                                        detailsArr.append({
                                                            department_id: getValues()?.department_id,
                                                            service_id: getValues()?.service_id,
                                                            norm: getValues()?.norm,
                                                        })
                                                        setValue('department_id', null)
                                                        setValue('service_id', null)
                                                        setValue('norm', '')
                                                    }}>
                                                <i className="bx bx-plus"/>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Label for="service_id">Xidmət</Label>
                                    <Controller render={({field: {value, onChange}}) => (
                                        <Select
                                            options={services}
                                            placeholder=""
                                            className={`w-100 ${errors?.service_id && 'is-invalid'}`}
                                            onChange={onChange}
                                            value={value}
                                            name="service_id"
                                            id="service_id"/>
                                    )} name="service_id" control={control}/>
                                    {FormHelper.generateFormFeedback(errors, 'service_id')}
                                </div>
                                <div className="mb-3">
                                    <Label for="norm">Norma</Label>
                                    <Controller name="norm" control={control}
                                                render={({field: {value, onChange}}) => (
                                                    <Input
                                                        name="norm"
                                                        id="norm"
                                                        value={value}
                                                        onChange={onChange}
                                                        className={errors?.norm && 'is-invalid'}
                                                    />
                                                )}/>
                                    {FormHelper.generateFormFeedback(errors, 'norm')}
                                </div>
                            </>
                        )}
                        <div className="mb-3 table-responsive" style={{maxWidth: '400px', overflow: 'auto'}}>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Şöbə</th>
                                    <th>Xidmət</th>
                                    <th>Norma</th>
                                    <th>Nəticə</th>
                                    <th>Tarix</th>
                                </tr>
                                </thead>
                                <tbody>
                                {detailsArr.fields.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{item?.department_id?.label}</td>
                                        <td>{item?.service_id?.label}</td>
                                        <td>{item?.norm}</td>
                                        <td>
                                            <input type="text" style={{
                                                background: 'transparent',
                                                border: 'none',
                                                width: '100%',
                                                height: '100%'
                                            }} defaultValue={item.result}
                                                   onChange={e => setValue(`details.${index}.result`, e.target.value)}/>
                                        </td>
                                        <td>{moment(item?.created_at).format('DD.MM.YYYY')}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Bağla</Button>
                    {data?.id && (
                        <PDFTemplate setPdf={setPdf} values={watch()}/>
                    )}
                    <Button disabled={loader || !detailsArr.fields.length} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default Add
